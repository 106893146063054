<template>
  <div class="KCmodule">
    <Header title="专利年费" index="首页" titleOne="工程专利" beforeTitle="专利年费" />
    <div class="content">
      <div class="checkbox">
        <el-checkbox-group v-model="checkList" @change="checkClick">
          <el-checkbox label="1" border>1月份</el-checkbox>
          <el-checkbox label="2" border>2月份</el-checkbox>
          <el-checkbox label="3" border>3月份</el-checkbox>
          <el-checkbox label="4" border>4月份</el-checkbox>
          <el-checkbox label="5" border>5月份</el-checkbox>
          <el-checkbox label="6" border>6月份</el-checkbox>
          <el-checkbox label="7" border>7月份</el-checkbox>
          <el-checkbox label="8" border>8月份</el-checkbox>
          <el-checkbox label="9" border>9月份</el-checkbox>
          <el-checkbox label="10" border>10月份</el-checkbox>
          <el-checkbox label="11" border>10月份</el-checkbox>
          <el-checkbox label="12" border>12月份</el-checkbox>
        </el-checkbox-group>
      </div>
      <div class="topUsers">
        <div class="top_l">
          <el-select
            clearable
            v-model="patprojectParams.patent_type"
            placeholder="请选择专利类型"
            @change="changePatentType"
          >
            <el-option label="发明专利" value="1">发明专利</el-option>
            <el-option label="实用新型" value="2">实用新型</el-option>
            <el-option label="外观设计" value="3">外观设计</el-option>
            <el-option label="植物新品种" value="4">植物新品种</el-option>
            <el-option label="国家新药" value="5">国家新药</el-option>
            <el-option label="集成电路布图设计专有权" value="6">集成电路布图设计专有权</el-option>
            <el-option label="国家级农作物品种" value="7">国家级农作物品种</el-option>
            <el-option label="国家一级中药保护品种" value="8">国家一级中药保护品种</el-option>
            <el-option label="其中：国防专利" value="9">其中：国防专利</el-option>
          </el-select>
          <el-input
            clearable
            v-model="patprojectParams.surplus_days"
            placeholder="请输入剩余天数"
            @keyup.enter.native="handleSearch()"
          ></el-input>
          <el-select
            clearable
            v-model="patprojectParams.is_late_payment"
            placeholder="请选择是否有滞纳金"
            @change="changePatentType"
          >
            <el-option label="否" value="0">否</el-option>
            <el-option label="是" value="1">是</el-option>
          </el-select>
          <el-select
            clearable
            v-model="patprojectParams.is_monitor"
            placeholder="请选择是否监控"
            @change="changePatentType"
          >
            <el-option label="否" value="0">否</el-option>
            <el-option label="是" value="1">是</el-option>
          </el-select>
          <el-date-picker
            v-model="patprojectParams.start_time"
            type="daterange"
            range-separator="至"
            start-placeholder="申请开始日期"
            end-placeholder="申请结束日期"
            @change="chooseTimeRange1"
          ></el-date-picker>
          <el-select
            clearable
            v-model="patprojectParams.handle_status"
            placeholder="请选择是否处理"
            @change="changePatentType"
          >
            <el-option label="否" value="0">否</el-option>
            <el-option label="是" value="1">是</el-option>
          </el-select>
          <div class="tabs" style="min-width: 80%;display: inline-block;">
            <el-select
              style="width: 100% !important;"
              multiple
              clearable
              v-model="patprojectParams.tag"
              placeholder="请选择标签"
              @change="changeTag"
            >
              <el-option
                v-for="(item,index) in options"
                :label="item.name"
                :value="item.id"
                :key="index"
              ></el-option>
            </el-select>
          </div>
          <el-input
            clearable
            v-model="patprojectParams.company_name"
            placeholder="请输入专利权人"
            @keyup.enter.native="handleSearch()"
          ></el-input>
          <el-input
            clearable
            v-model="patprojectParams.patent_name"
            placeholder="请输入专利名称"
            @keyup.enter.native="handleSearch()"
          ></el-input>
          <el-input
            clearable
            v-model="patprojectParams.internal_code"
            placeholder="请输入内部编号"
            @keyup.enter.native="handleSearch()"
          ></el-input>
          <el-input
            clearable
            v-model="patprojectParams.app_code"
            placeholder="请输入专利号"
            @keyup.enter.native="handleSearch()"
          ></el-input>
          <el-input
            clearable
            v-model="patprojectParams.applicant"
            placeholder="请输入所属客户"
            @keyup.enter.native="handleSearch()"
          ></el-input>
          <el-button class="search" @click="handleSearch()">查询</el-button>
          <!-- <el-button disabled @click="exportAll()" class="import">全部导出</el-button> -->
        </div>
      </div>
      <!-- <keep-alive> -->
      <el-table v-loading="loading" element-loading-text="正在加载中···" :data="patproject.data">
        <el-table-column prop="app_code" label="专利号" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            {{
            scope.row.app_code ? scope.row.app_code : "-"
            }}
          </template>
        </el-table-column>
        <el-table-column prop="patent_name" label="专利名称" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            {{
            scope.row.patent_name ? scope.row.patent_name : "-"
            }}
          </template>
        </el-table-column>
        <el-table-column prop="tag" label="标签" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <el-tooltip class="item" effect="dark" content="Bottom Center 提示文字" placement="bottom">
              <template slot="content">
                <div class="tooltip-content">
                  <span style="color: #A86201;" v-if="scope.row.tag.includes('系统重复提交')">
                    系统重复提交
                    <br />
                  </span>
                  <span style="color: #8408FF;" v-if="scope.row.tag.includes('专利转让/变更')">
                    专利转让/变更
                    <br />
                  </span>
                  <span style="color: #1CC2FF;" v-if="scope.row.tag.includes('非正常申请重写')">
                    非正常申请重写
                    <br />
                  </span>
                  <span style="color: #03B262;" v-if="scope.row.tag.includes('非正常申请撤回')">
                    非正常申请撤回
                    <br />
                  </span>
                  <span style="color: #FFA01D;" v-if="scope.row.tag.includes('退费')">
                    退费
                    <br />
                  </span>
                  <span style="color: #FF2255;" v-if="scope.row.tag.includes('驳回/撤回重写')">
                    驳回/撤回重写
                    <br />
                  </span>
                  <span style="color: #ADB1B4;" v-if="scope.row.tag.includes('假专利')">
                    假专利
                    <br />
                  </span>
                  <span style="color: #F74691;" v-if="scope.row.tag.includes('代提交')">
                    代提交
                    <br />
                  </span>
                </div>
              </template>
              <span v-if="!scope.row.tag">-</span>
              <span style="color: #A86201;" v-if="scope.row.tag.includes('系统重复提交')">系统重复提交</span>
              <span style="color: #8408FF;" v-else-if="scope.row.tag.includes('专利转让/变更')">专利转让/变更</span>
              <span style="color: #1CC2FF;" v-else-if="scope.row.tag.includes('非正常申请重写')">非正常申请重写</span>
              <span style="color: #03B262;" v-else-if="scope.row.tag.includes('非正常申请撤回')">非正常申请撤回</span>
              <span style="color: #FFA01D;" v-else-if="scope.row.tag.includes('退费')">退费</span>
              <span style="color: #FF2255;" v-else-if="scope.row.tag.includes('驳回/撤回重写')">驳回/撤回重写</span>
              <span style="color: #ADB1B4;" v-else-if="scope.row.tag.includes('假专利')">假专利</span>
              <span style="color: #F74691;" v-else-if="scope.row.tag.includes('代提交')">代提交</span>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column prop="company_name" label="专利权人" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            {{
            scope.row.company_name ? scope.row.company_name : "-"
            }}
          </template>
        </el-table-column>
        <el-table-column prop="patent_type" label="专利类型" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            {{
            scope.row.patent_type ? scope.row.patent_type : "-"
            }}
          </template>
        </el-table-column>
        <el-table-column prop="content" label="年费内容" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            {{
            scope.row.content ? scope.row.content : "-"
            }}
          </template>
        </el-table-column>
        <el-table-column prop="amount" label="年费金额" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            {{
            scope.row.amount ? scope.row.amount : "-"
            }}
          </template>
        </el-table-column>
        <el-table-column prop="end_date" label="截止日期" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            {{
            scope.row.end_date ? scope.row.end_date : "-"
            }}
          </template>
        </el-table-column>
        <el-table-column prop="late_payment_fee" label="年费滞纳金" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            {{
            scope.row.late_payment_fee ? scope.row.late_payment_fee : "-"
            }}
          </template>
        </el-table-column>
        <el-table-column prop="late_end_date" label="滞纳金截止日期" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            {{
            scope.row.late_end_date ? scope.row.late_end_date : "-"
            }}
          </template>
        </el-table-column>
        <el-table-column prop="app_date" label="申请日期" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            {{
            scope.row.app_date ? scope.row.app_date : "-"
            }}
          </template>
        </el-table-column>
        <el-table-column prop="operation" label="操作" width="150px">
          <template slot-scope="scope">
            <el-button class="edit" size="mini" @click="patentEdit(scope.row.patent_id)">编辑</el-button>
            <el-button class="del" size="mini" @click="handleDelete(scope.row.id)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- </keep-alive> -->
      <div class="block" v-if="patproject.total > 0">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="patprojectParams.page"
          :page-sizes="[2, 10, 20, 40]"
          :page-size="patprojectParams.limit"
          layout="sizes, prev, pager, next,total,jumper"
          :total="patproject.total"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>
    
    <script>
// import { mapGetters, mapState } from "vuex";
export default {
  inject: ["reload"],
  data () {
    return {
      checked2: "",
      options: [
        {
          name: '系统重复提交',
          id: '1'
        },

        {
          name: '专利转让/变更',
          id: '2'
        },

        {
          name: '非正常申请重写',
          id: '3'
        },
        {
          name: '非正常申请撤回',
          id: '4'
        },
        {
          name: '退费',
          id: '5'
        },
        {
          name: '驳回/撤回重写',
          id: '6'
        },
        {
          name: '假专利',
          id: '7'
        },
        {
          name: '代提交',
          id: '8'
        }
      ],
      checkList: [],
      dialogTableVisible: false,
      opt: { id: '0', name: '全部' },
      loading: true,
      province: [],
      city: [],
      town: [],
      patprojectTotal: 0,
      patprojectParams: {
        page: 1,
        limit: 10,
        keywords: "",
        patent_name: "",
        patent_type: "",
        status: "",
        company_name: "",
        order: "id desc",
      },
      privilige_list: {},
      patproject: [],
    };
  },
  activated () {
    this.patprojectParams
    // this.$store.dispatch("getpatproList", this.patprojectParams);
  },
  created () {
    this.getList()
    let arr = localStorage.getItem('privilige')
    this.privilige_list = JSON.parse(arr)
    let status = localStorage.getItem('patent_header_status')
    if (JSON.parse(status)) {
      this.headerStatus = JSON.parse(status)
    }
  },
  methods: {
    patentEdit (patent_id) {
      this.$router.push({ path: '/patent/patent_edit', query: { id: patent_id, title: '专利年费', type: 'patent_fee' } })
    },
    getList () {
      this.axios.get('/api/patent_fee/annual_list', this.patprojectParams).then((res) => {
        this.patproject = res.data
        setTimeout(() => {
          this.loading = false
        }, 100)
      })
    },
    changeTag (x) {
      this.loading = true
      this.axios.get('/api/patent_fee/annual_list', { params: { ...this.patprojectParams, page: '', tag: x.join() } }).then((res) => {
        this.patproject = res.data
        this.loading = false
      })
      this.getList()
    },
    checkClick (a) {
      console.log(a)
      this.loading = true
      this.axios.get('/api/patent_fee/annual_list', { params: { ...this.patprojectParams, page: '', month: a } }).then((res) => {
        this.patproject = res.data
        this.loading = false
      })
    },
    // exportAll () {
    //   // this.$router.push('')
    //   this.$router.push({
    //     path: "/data/data_export/go_export",
    //     query: { item: "project_patent" },
    //   });
    // },
    chooseTimeRange1 (t) {
      console.log(t);
      this.patprojectParams.start_time = t[0]
      this.patprojectParams.end_time = t[1]
      this.loading = true
      this.axios.get('/api/patent_fee/annual_list', { params: { ...this.patprojectParams, page: '' } }).then((res) => {
        this.patproject = res.data
        this.loading = false
      })
      this.getList()
    },
    // chooseTimeRange2 (t) {
    //   this.loading = true
    //   this.axios.get('/api/patent_fee/annual_list', { params: { ...this.patprojectParams, page: '' } }).then((res) => {
    //     this.patproject = res.data
    //     this.loading = false
    //   })
    //   // this.$store.dispatch("getpatproList", this.patprojectParams);
    //   this.getList()
    // },
    handleSizeChange (val) {
      this.patprojectParams.limit = val;
      this.loading = true
      // this.$store.dispatch("getpatproList", this.patprojectParams);
      this.axios.get('/api/patent_fee/annual_list', { params: { ...this.patprojectParams } }).then((res) => {
        this.patproject = res.data
        setTimeout(() => {
          this.loading = false
        }, 100)
      })
    },
    handleCurrentChange (val) {
      this.patprojectParams.page = val;
      this.loading = true
      // this.$store.dispatch("getpatproList", { ...this.patprojectParams, keywords: '' });
      this.axios.get('/api/patent_fee/annual_list', { params: { ...this.patprojectParams, keywords: '' } }).then((res) => {
        this.patproject = res.data
        setTimeout(() => {
          this.loading = false
        }, 100)
      })
    },
    changePatentType () {
      this.loading = true
      this.axios.get('/api/patent_fee/annual_list', { params: { ...this.patprojectParams, page: '' } }).then((res) => {
        this.patproject = res.data
        this.loading = false
      })
    },
    handleSearch () {
      // this.$store.dispatch("getpatproList", { ...this.patprojectParams, page: '', limit: '' });
      this.loading = true
      this.axios.get('/api/patent_fee/annual_list', { params: { ...this.patprojectParams, page: '' } }).then((res) => {
        this.patproject = res.data
        this.loading = false
      })
    },
    handleDelete (id) {
      this.$confirm("确定删除此项目数据，删除后无法恢复！", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then((res) => {
          this.axios.delete("/api/patent_fee/delete_annual", {
            params: {
              id: id,
            },
          }).then(() => {
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            this.getList()
          });
        })
        .catch((res) => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
  },
};
    </script>
    
    <style scoped="scoped">
.KCmodule .import {
  float: right;
  background: #edeef4;
  color: #909399;
  border: none;
}
::v-deep .topUsers .el-input {
  width: 224px !important;
}
div/deep/.tabs .el-input {
  min-width: 100% !important;
}
.el-select {
  margin-bottom: 15px;
}
div/deep/.el-range-editor.el-input__inner {
  margin-right: 15px;
}
div/deep/.el-checkbox {
  /* display: inline-block;
    width: 87px;*/
  height: 28px;
  padding: 5px 10px;
  /* margin-right: 0; */
  margin-bottom: 20px;
}
div/deep/.el-checkbox__label {
  font-size: 14px;
  color: #606266;
}
.checkbox {
  display: flex;
  flex-wrap: wrap;
  /* margin-bottom: 20px; */
}
</style>
    